
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  props: {
    isIrisAnchor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
